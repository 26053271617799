// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { GrabsonIcon } from "components/icons";
import { Tooltip } from "components/popups";

import styles from "./HeaderContentWithUpdatedLabel.scss";

export const HeaderContentWithUpdatedLabel = ({
  content,
  isUpdated,
  updatedLabelTooltipContent,
}) => {
  return content ? (
    <div className={styles.header}>
      <span>{content}</span>
      {isUpdated && (
        <Tooltip
          alignment="bottom"
          content={updatedLabelTooltipContent}
          className={styles.iconWithTooltip}
          disabled={!updatedLabelTooltipContent}
        >
          <GrabsonIcon
            name="promotions"
            size="normal"
            className={styles.icon}
            title={updatedLabelTooltipContent ? null : "updated"}
          />
        </Tooltip>
      )}
    </div>
  ) : null;
};

HeaderContentWithUpdatedLabel.propTypes = {
  content: PropTypes.node.isRequired,
  isUpdated: PropTypes.bool.isRequired,
  updatedLabelTooltipContent: PropTypes.node,
};
