// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { PureComponent } from "react";

import PropTypes from "prop-types";

import { Row, Column, Section, Subsection } from "components/layout";

import { SECTIONS } from "../../shared/constants";
import { SnapURIs } from "../../shared/SnapURIs";
import { SOURCE_TYPES_HELP } from "../../SnapEdit/components/SelectSource/sourceDefinition";

export class SnapDetails extends PureComponent {
  static propTypes = {
    revision: PropTypes.object,
    revisionType: PropTypes.string,
  };

  getKeywordsDetails() {
    const { revision } = this.props;
    return revision.categories_from_tags !== "false"
      ? revision.keyword_prefix
        ? SECTIONS.KEYWORDS.enabledWithPrefix.replace(
            "$0",
            revision.keyword_prefix
          )
        : SECTIONS.KEYWORDS.enabled
      : SECTIONS.KEYWORDS.disabled;
  }

  render() {
    const { revision, revisionType } = this.props;
    const sourceName = SOURCE_TYPES_HELP[revision.vt_type];

    return (
      <Section header={SECTIONS.DETAILS.header}>
        <Row>
          <Column>
            <Subsection header={SECTIONS.SOURCE.header}>
              {sourceName ? sourceName.title : SECTIONS.SOURCE.undefined}
            </Subsection>
          </Column>
          <Column>
            <SnapURIs
              snapURL={revision.app_display_url}
              preview={revisionType === "DRAFT"}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Subsection header={SECTIONS.KEYWORDS.header}>
              {this.getKeywordsDetails()}
            </Subsection>
          </Column>
          <Column>
            {/* This columns needs to be implemented in backend first */}
            {/* <Subsection header={SECTIONS.ICONS.header}>
              Not implemented
            </Subsection> */}
          </Column>
        </Row>
      </Section>
    );
  }
}
