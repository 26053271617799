// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import { Component } from "react";

import PropTypes from "prop-types";

import { Row, Column, Section } from "components/layout";
import { Link } from "components/typography";
import { RestrictedArea } from "containers/Permissions";
import { ALL_PROVIDERS, ALL_MODERATORS } from "containers/Permissions/groups";
import { Markets } from "pages/Applications/_shared/Markets";
import { NO_VALUE_PLACEHOLDER } from "src/constants";
import { trans } from "src/translations";

import { SubsectionWithUpdatedStatus } from "./_shared";
import {
  IsVersionUpdated,
  HeaderContentWithUpdatedLabel,
} from "./_shared/IsVersionUpdated";
import { createBasicDetailsDiff } from "./_utils/basicDetails";
import { ActionButtons } from "./ActionButtons";
import { Assets } from "./Assets";
import { BasicDetails } from "./BasicDetails";
import { BasicDetailsTooltip } from "./BasicDetailsTooltip";
import { Changelog } from "./Changelog";
import { Owner } from "./Owner";
import { Revenue } from "./Revenue";
import { RundataSection } from "./Rundata";
import { TechnicalDetails } from "./TechnicalDetails";
import styles from "./Version.scss";

export class Version extends Component {
  static propTypes = {
    versionData: PropTypes.object,
    allCountries: PropTypes.array,
    categories: PropTypes.array,
    languages: PropTypes.array,
    application: PropTypes.object.isRequired,
    canEditVersion: PropTypes.bool.isRequired,
    appOwner: PropTypes.object.isRequired,
    upgradeOrganizationPlan: PropTypes.func,
  };

  static defaultProps = {
    versionData: {},
    allCountries: [],
    categories: [],
    languages: [],
  };

  render() {
    const {
      versionData,
      allCountries,
      categories,
      languages,
      application,
      appOwner,
      canEditVersion,
      upgradeOrganizationPlan,
    } = this.props;
    const { images, category, support_email, changelog, profiles } =
      versionData;
    const appCategory = categories.find((c) => c.id === category);

    return (
      <div>
        <Changelog {...changelog} />
        <Section dataTestId="actions-section">
          <ActionButtons
            versionData={versionData}
            application={application}
            canEditVersion={canEditVersion}
          />
        </Section>
        <Section
          header={trans.APP_DETAILS_VERSION__SECTION_ASSETS()}
          dataTestId="assets-section"
        >
          <Assets images={images} />
        </Section>
        <Row>
          <Column>
            <IsVersionUpdated isUpdated={createBasicDetailsDiff}>
              {(isUpdated) => (
                <Section
                  header={
                    <HeaderContentWithUpdatedLabel
                      content={trans.APP_DETAILS_VERSION__SECTION_BASIC_DETAILS()}
                      isUpdated={isUpdated}
                      updatedLabelTooltipContent={
                        <BasicDetailsTooltip languages={languages} />
                      }
                    />
                  }
                  dataTestId="basic-details-section"
                >
                  <BasicDetails
                    languages={languages}
                    versionData={versionData}
                  />
                </Section>
              )}
            </IsVersionUpdated>
            <Revenue monetization={versionData.monetization} />
            <TechnicalDetails
              {...versionData.technicalDetails}
              appType={application.type}
              appEnvironments={versionData.environments}
              profiles={versionData.profiles}
            />
          </Column>
          <Column>
            <RundataSection
              applicationUrl={versionData.application_url}
              appTemplate={application.template}
              appType={application.type}
              profiles={profiles}
            />
            <Section
              header={trans.APP_DETAILS_VERSION__SECTION_OTHER_DETAILS()}
              dataTestId="other-details-section"
            >
              <SubsectionWithUpdatedStatus
                headerContent={trans.CATEGORY()}
                isUpdatedPath="category"
              >
                <p className={styles.titleText}>
                  {appCategory ? appCategory.human_name : NO_VALUE_PLACEHOLDER}
                </p>
              </SubsectionWithUpdatedStatus>
              <SubsectionWithUpdatedStatus
                headerContent={trans.APP_DETAILS_VERSION__SUPPORT_EMAIL()}
                isUpdatedPath="support_email"
                subsectionClassName={styles.supportEmail}
              >
                <Link
                  className={styles.titleText}
                  href={`mailto:${support_email}`}
                >
                  {support_email}
                </Link>
              </SubsectionWithUpdatedStatus>
              <SubsectionWithUpdatedStatus
                headerContent={trans.APP_DETAILS_VERSION__DESC_FOR_MODERATORS()}
                isUpdatedPath="description_for_moderators"
                subsectionClassName={styles.descriptionText}
              >
                {versionData.description_for_moderators}
              </SubsectionWithUpdatedStatus>
              <RestrictedArea
                allowed={[...ALL_PROVIDERS, ...ALL_MODERATORS]}
                showFallback={false}
              >
                <SubsectionWithUpdatedStatus
                  headerContent={trans.APP_DETAILS_VERSION__ADDITIONAL_COMMENTS()}
                  isUpdatedPath="additional_comments"
                  subsectionClassName={styles.descriptionText}
                >
                  {versionData.additional_comments
                    ? versionData.additional_comments
                    : NO_VALUE_PLACEHOLDER}
                </SubsectionWithUpdatedStatus>
              </RestrictedArea>
            </Section>
            <Owner
              organization={appOwner}
              upgradeOrganizationPlan={upgradeOrganizationPlan}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Section
              header={trans.APP_DETAILS_VERSION__SECTION_MARKETS_FOR()}
              dataTestId="markets-designed-section"
            >
              <Markets
                countryCodesKey="app_designed_for"
                allCountries={allCountries}
                selectedLocations={versionData.markets.app_designed_for}
              />
            </Section>
          </Column>
          <Column>
            <Section
              header={trans.APP_DETAILS_VERSION__SECTION_MARKETS_AVAIL()}
              dataTestId="markets-available-section"
            >
              <Markets
                countryCodesKey="app_is_available_on"
                allCountries={allCountries}
                selectedLocations={versionData.markets.app_is_available_on}
              />
            </Section>
          </Column>
        </Row>
      </div>
    );
  }
}
