// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.
import PropTypes from "prop-types";

import { Info } from "components/feedback";
import { Header } from "components/typography";
import { SECTIONS } from "pages/Snap/shared/constants";
import { useSnapState } from "pages/Snap/shared/SnapState/useSnapState";
import { SnapURIs } from "pages/Snap/shared/SnapURIs";

import styles from "./SnapPreview.scss";

export function SnapPreview({ appId, pristine }) {
  const { getLastVersion, isNew, loading } = useSnapState({ id: appId });

  return (
    <div className={styles.snapPreview}>
      <Header color="secondary" uppercase={true} bold={true}>
        {SECTIONS.PREVIEW.header}
      </Header>
      <div className={styles.textWrapper}>
        {!loading && !isNew ? (
          <>
            <p>{SECTIONS.PREVIEW.content}</p>
            <SnapURIs
              snapURL={getLastVersion()?.revision.app_display_url}
              preview={true}
            />

            {pristine ? (
              <p>{SECTIONS.PREVIEW.info}</p>
            ) : (
              <Info type="warning" className={styles.warning}>
                {SECTIONS.PREVIEW.outdated}
              </Info>
            )}
          </>
        ) : (
          <p>{SECTIONS.PREVIEW.no_preview}</p>
        )}
      </div>
    </div>
  );
}

SnapPreview.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  appId: PropTypes.string,
  pristine: PropTypes.bool,
};
